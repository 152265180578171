import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import { FixedObject } from 'gatsby-image';
import SmartImage from '../components/SmartImage';
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { Logo } from '../components/Logo';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  innerNarrow,
  outer,
  outerFull,
  Section,
  SectionContent,
  SectionContentColumn,
  SectionContentContent,
  SectionIntro,
  SiteDescription,
  SiteHeader,
  SiteHeaderHero,
  SiteHeaderContent,
  SiteMain,
  SiteHeaderStyles,
} from '../styles/shared';
import config from '../website-config';
import { PageContext } from './post';
import GiveawayForm from '../components/forms/GiveawayForm';
export interface IndexProps {
  pageContext: {
    currentPage: number;
    numPages: number;
  };
  data: {
    header: {
      childImageSharp: {
        fixed: FixedObject;
      };
    };
    allMarkdownRemark: {
      edges: Array<{
        node: PageContext;
      }>;
    };
  };
}

const IndexPage: React.FC<IndexProps> = props => {
  const { width, height } = props.data.header.childImageSharp.fixed;
  const homepage = props.data.homepage;
  const giveaways = props.data.giveaways.edges;

  return (
    <IndexLayout>
      <Helmet>
        <html lang={config.lang} />
        <title>{config.title}</title>
        <meta name="description" content={config.description} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={config.title} />
        <meta property="og:description" content={config.description} />
        <meta property="og:url" content={config.siteUrl} />
        <meta
          property="og:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`}
        />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.googleSiteVerification && (
          <meta name="google-site-verification" content={config.googleSiteVerification} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={config.title} />
        <meta name="twitter:description" content={config.description} />
        <meta name="twitter:url" content={config.siteUrl} />
        <meta
          name="twitter:image"
          content={`${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`}
        />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        <meta property="og:image:width" content={width.toString()} />
        <meta property="og:image:height" content={height.toString()} />
      </Helmet>
      <Wrapper>
        <div
          css={[outer, SiteHeader, SiteHeaderStyles]}
          className="site-header-background"
        >
          <div css={inner}>
            <SiteNav isHome />
            <SiteHeaderContent className="site-header-content">
              <SiteHeaderHero>
                <Logo style={{width: '60%'}} />
              </SiteHeaderHero>

              <SiteDescription>{config.description}</SiteDescription>
            </SiteHeaderContent>
          </div>
        </div>

        <div css={outerFull}>
          {giveaways.length == 0 && (
            <Section css={innerNarrow}>
              <SectionIntro>
                <h3>No Active Giveaways</h3>
                <p>Please check back soon!</p>
              </SectionIntro>
            </Section>
          )}

          {giveaways.length > 0 && giveaways.map((giveaway, _index) => {
            return (
              (giveaway.node.frontmatter.draft !== true ||
                process.env.NODE_ENV !== 'production') &&
                giveaway.node.frontmatter.title == homepage.frontmatter.giveaway[0] && (
                <Section css={innerNarrow}>
                  <SectionIntro>
                    <h3>{ giveaway.node.frontmatter.title }</h3>
                    <p>{ giveaway.node.frontmatter.tagline }</p>
                  </SectionIntro>
                  <SectionContent>
                    <GiveawayForm key={giveaway.node.fields.slug} giveaway={giveaway.node} />
                  </SectionContent>
                </Section>
              )
            );
          })}
        </div>

        {/* TODO - should we move our sections to children? */}
        {props.children}

        {/* <Footer /> */}
      </Wrapper>
    </IndexLayout>
  );
};

export const staticQuery = graphql`
  query {
    header: file(relativePath: { eq: "jihi-social-share.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 600, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    homepage: markdownRemark(fields: {template_key: {eq: "page.homepage"}}) {
      id
      frontmatter {
        title
        giveaway
      }
    }

    giveaways: allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: ASC}
      filter: {fields: {template_key: {eq: "collection.giveaway"}}, frontmatter: {draft: {ne: true}}}
    ) {
      edges {
        node {
          frontmatter {
            title
            tagline
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
