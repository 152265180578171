// Render Prop
import React from 'react';
import { Formik, Form, Field, FormikHelpers as FormikActions } from 'formik';

import {
  alert,
  alertSuccess,
  alertError,
  form,
  styledField,
  styledInput,
} from '../../styles/forms';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
}

const GiveawayForm = ({giveaway}) => (
  <>
    <Formik
      initialValues={
        {
          email: ''
        }
      }
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { resetForm, setStatus }) => {
        // TODO - use axios here?
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'giveaway', ...values })
        })
        .then(res => {
          setStatus(res.status)
          if (res.status === 200) {
            resetForm()
            setStatus({
              sent: true,
              msg: 'Thank you for entering!'
            })
          }
        })
        .catch(_err => {
          resetForm()
          setStatus({
            sent: false,
            msg: 'There was a problem submitting the form. Please contact sayhi@jihi.com for help if the problem persists.'
          })
        })
      }}
    >
      {({ isSubmitting, status }) => (
        <>
          <Form data-netlify='true' name='giveaway' css={form}>
            <fieldset>
              <Field
                name='giveaway'
                type='hidden'
                value={giveaway.frontmatter.title}
              />

              <Field
                name='form-name'
                type='hidden'
                value='giveaway'
              />

              <div css={styledField}>
                {/* <label>Your Email</label> */}
                <Field
                  name='email'
                  type='email'
                  required={true}
                  css={styledInput}
                  placeholder='Enter your email'
                />
              </div>
            </fieldset>

            <button
              type='submit'
              disabled={isSubmitting}
              className='Button Button--full Button--primary'
            >
              Submit
            </button>
          </Form>

          {status && status.msg && (
            <p
              css={[alert, status.sent ? alertSuccess : alertError]}
            >
              {status.msg}
            </p>
          )}
        </>
      )}
    </Formik>
  </>
);

export default GiveawayForm;
